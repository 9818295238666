import './styles';

function toggleModal(e) {
  e.preventDefault();

  const modalId = e.target.closest('button,a').getAttribute('data-modal-target');
  const modal = document.getElementById(modalId);
  const backdrop = modal.querySelector('.modal-backdrop');

  modal.classList.toggle('hidden');
  modal.classList.toggle('flex');
  backdrop.classList.toggle('hidden');
  backdrop.classList.toggle('flex');
}

window.toggleModal = toggleModal;
